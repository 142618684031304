import React from "react";
import Page from "~/templates/Page";

const data = {
  page: {
    metadata: {
      title: "Frequently asked questions",
      description: {
        internal: {
          content:
            "Find out more about PT Blink, the benefits and how to register.",
        },
      },
      image: null,
    },
    sections: [
      {
        __typename: "ContentfulTextBanner",
        heading: "Frequently asked questions",
        subHeading: null,
        buttonText: null,
        buttonLink: null,
        content: {
          raw: '{"nodeType":"document","data":{},"content":[{"nodeType":"paragraph","content":[{"nodeType":"text","value":"Find out more about PT Blink, the benefits and how to register.","marks":[],"data":{}}],"data":{}}]}',
        },
        id: "20734288-a901-51a8-871e-0777e1e9b200",
      },
      {
        __typename: "ContentfulFaqSection",
        groups: [
          {
            id: "1e1a7512-a16f-5034-bcc5-90c9f21fbf5a",
            title: "General questions",
            items: [
              {
                id: "a121d1ad-4422-54f9-99ec-012030be6e38",
                question: "Who is PT Blink?​",
                answer: {
                  raw: '{"nodeType":"document","data":{},"content":[{"nodeType":"paragraph","data":{},"content":[{"nodeType":"text","value":"PT Blink is a technology company with a deep structural engineering heritage. We enable the Blink DMI","marks":[],"data":{}},{"nodeType":"text","value":"®","marks":[],"data":{}},{"nodeType":"text","value":" process by licensing our technology platform, structural technology and other related intellectual property for use by ecosystem partners in delivering a property development project and operating the built asset. ","marks":[],"data":{}}]},{"nodeType":"paragraph","data":{},"content":[{"nodeType":"text","value":"The ","marks":[],"data":{}},{"nodeType":"hyperlink","data":{"uri":"/dmi-process/"},"content":[{"nodeType":"text","value":"Blink DMI® process","marks":[],"data":{}}]},{"nodeType":"text","value":" is currently applied manually while the cloud-based technology platform and transactional marketplace is being created.​","marks":[],"data":{}}]}]}',
                },
              },
              {
                id: "786c5815-cab2-522d-8dad-abf3beb86acb",
                question: "What is Blink DMI®?",
                answer: {
                  raw: '{"nodeType":"document","data":{},"content":[{"nodeType":"paragraph","data":{},"content":[{"nodeType":"text","value":"","marks":[],"data":{}},{"nodeType":"hyperlink","data":{"uri":"/dmi-process/"},"content":[{"nodeType":"text","value":"Blink DMI®","marks":[],"data":{}}]},{"nodeType":"text","value":" is a modern method of construction which stands for Design, Manufacture, Integrate. ","marks":[],"data":{}}]},{"nodeType":"paragraph","data":{},"content":[{"nodeType":"text","value":"Like the commonly known ","marks":[],"data":{}},{"nodeType":"hyperlink","data":{"uri":"https://www.designingbuildings.co.uk/wiki/Design_for_Manufacture_and_Assembly_(DfMA)#External_resources"},"content":[{"nodeType":"text","value":"DfMA (Design for Manufacture and Assembly)","marks":[],"data":{}}]},{"nodeType":"text","value":" concept, Blink DMI","marks":[],"data":{}},{"nodeType":"text","value":"®","marks":[],"data":{}},{"nodeType":"text","value":" adopts a design-driven approach that harnesses the control, precision and safety of offsite manufacturing and the efficiency and speed of onsite assembly. ","marks":[],"data":{}}]}]}',
                },
              },
              {
                id: "1dcaab03-740e-549e-9108-36931a7d3f3f",
                question: "How is a Blink DMI® project delivered?",
                answer: {
                  raw: '{"nodeType":"document","data":{},"content":[{"nodeType":"paragraph","data":{},"content":[{"nodeType":"text","value":"The Blink DMI","marks":[],"data":{}},{"nodeType":"text","value":"®","marks":[],"data":{}},{"nodeType":"text","value":" process takes place in an open, ","marks":[],"data":{}},{"nodeType":"hyperlink","data":{"uri":"/dmi-partners"},"content":[{"nodeType":"text","value":"horizontally integrated network of accredited partners","marks":[],"data":{}}]},{"nodeType":"text","value":", enabling customised prefabricated components to be designed and sourced directly from a range of suppliers and manufacturers. Once accredited by PT Blink, anyone can ","marks":[],"data":{}},{"nodeType":"hyperlink","data":{"uri":"/register"},"content":[{"nodeType":"text","value":"join","marks":[],"data":{}}]},{"nodeType":"text","value":".","marks":[],"data":{}}]},{"nodeType":"paragraph","data":{},"content":[{"nodeType":"text","value":"The process enables the coordinated design and manufacture of dimensionally precise building components with creative freedom for any architectural design.","marks":[],"data":{}}]},{"nodeType":"paragraph","data":{},"content":[{"nodeType":"text","value":"\\n\\n\\n","marks":[],"data":{}}]}]}',
                },
              },
              {
                id: "c9b787a4-bc08-5998-949e-7d9d20419df9",
                question:
                  "Does PT Blink allow the use of other structural systems?",
                answer: {
                  raw: '{"nodeType":"document","data":{},"content":[{"nodeType":"paragraph","data":{},"content":[{"nodeType":"text","value":"Yes, the Blink DMI","marks":[],"data":{}},{"nodeType":"text","value":"®","marks":[],"data":{}},{"nodeType":"text","value":" platform","marks":[],"data":{}},{"nodeType":"hyperlink","data":{"uri":"/dmi-platform"},"content":[{"nodeType":"text","value":" is flexible to enable the use of other structural systems that enab","marks":[],"data":{}}]},{"nodeType":"text","value":"le offsite manufacturing as required to meet the project design outcomes. ","marks":[],"data":{}}]},{"nodeType":"paragraph","data":{},"content":[{"nodeType":"text","value":"\\n\\n\\n","marks":[],"data":{}}]}]}',
                },
              },
            ],
          },
          {
            id: "901bc7a1-df97-50cd-9d38-2e82fdd8885d",
            title: "Construction benefits",
            items: [
              {
                id: "151efb49-122c-51c0-a29d-f4132d186082",
                question:
                  "What are the main benefits of Blink DMI® compared with traditional construction methods?",
                answer: {
                  raw: '{"nodeType":"document","data":{},"content":[{"nodeType":"paragraph","data":{},"content":[{"nodeType":"text","value":"","marks":[],"data":{}},{"nodeType":"hyperlink","data":{"uri":"/blog/save-construction-project-time"},"content":[{"nodeType":"text","value":"Blink DMI® substantially reduces project time and cost.","marks":[],"data":{}}]},{"nodeType":"text","value":" This reduces the primary risks of budget and time blowouts for owners and developers. ","marks":[],"data":{}}]},{"nodeType":"paragraph","data":{},"content":[{"nodeType":"text","value":"The DMI method also brings all the advantages of automobile or aircraft manufacture to the built environment. These include safe, controlled factory workspaces and processes, millimetre-precision for low wastage and high-quality assurance, and mass customisation.","marks":[],"data":{}}]},{"nodeType":"paragraph","data":{},"content":[{"nodeType":"text","value":"\\n\\n\\n","marks":[],"data":{}}]}]}',
                },
              },
              {
                id: "e47e7f93-3553-5628-894e-63bee2750a3c",
                question:
                  "What are the benefits of Blink DMI® compared with other prefabrication or industrialised construction methods?",
                answer: {
                  raw: '{"nodeType":"document","data":{},"content":[{"nodeType":"paragraph","data":{},"content":[{"nodeType":"text","value":"Blink DMI","marks":[],"data":{}},{"nodeType":"text","value":"®","marks":[],"data":{}},{"nodeType":"text","value":" combines ","marks":[],"data":{}},{"nodeType":"hyperlink","data":{"uri":"/components/"},"content":[{"nodeType":"text","value":"unique structural technology","marks":[],"data":{}}]},{"nodeType":"text","value":" with a ","marks":[],"data":{}},{"nodeType":"hyperlink","data":{"uri":"/blink-dmi-cube/"},"content":[{"nodeType":"text","value":"parametric design too","marks":[],"data":{}}]},{"nodeType":"text","value":"l to enable engineers and architects to design with creative freedom for any shape of building while achieving complete dimensional accuracy. ","marks":[],"data":{}}]},{"nodeType":"paragraph","data":{},"content":[{"nodeType":"text","value":"The ","marks":[],"data":{}},{"nodeType":"hyperlink","data":{"uri":"/dmi-platform"},"content":[{"nodeType":"text","value":"Blink DMI® platform","marks":[],"data":{}}]},{"nodeType":"text","value":" we are creating also addresses critical issues of fragmentation in the supply chain through our open manufacturer marketplace. ","marks":[],"data":{}}]},{"nodeType":"paragraph","data":{},"content":[{"nodeType":"text","value":"This saves time, eliminates layers of compounded margin and enables diversification of supply.","marks":[],"data":{}}]},{"nodeType":"paragraph","data":{},"content":[{"nodeType":"text","value":"\\n\\n\\n","marks":[],"data":{}}]}]}',
                },
              },
              {
                id: "a097f35f-09d1-593e-867d-a81c50c54c10",
                question:
                  "What is the benefit of PT Blink’s structural technology?",
                answer: {
                  raw: '{"nodeType":"document","data":{},"content":[{"nodeType":"paragraph","data":{},"content":[{"nodeType":"text","value":"The ","marks":[],"data":{}},{"nodeType":"hyperlink","data":{"uri":"/components/"},"content":[{"nodeType":"text","value":"post-tensioned steel Blink Backbone®","marks":[],"data":{}}]},{"nodeType":"text","value":" enables long-span structures to be built quickly and efficiently and eliminates the need for a building core. ","marks":[],"data":{}}]},{"nodeType":"paragraph","data":{},"content":[{"nodeType":"text","value":"As a result, the superstructure is taken off the critical path. The structure also becomes the reference point for all other components which can be produced concurrently offsite, drastically reducing project time by up to 40%.","marks":[],"data":{}}]},{"nodeType":"paragraph","data":{},"content":[{"nodeType":"text","value":"\\n\\n\\n","marks":[],"data":{}}]}]}',
                },
              },
            ],
          },
          {
            id: "d735c101-2614-56ad-95d7-e306bb866b12",
            title: "How to join",
            items: [
              {
                id: "18ec05c1-a39d-5253-9e89-0f77046671c6",
                question: "How do you become a Blink DMI® partner?",
                answer: {
                  raw: '{"data":{},"content":[{"data":{},"content":[{"data":{},"marks":[],"value":"The ","nodeType":"text"},{"data":{"uri":"/dmi-partners"},"content":[{"data":{},"marks":[],"value":"Blink DMI® partner network","nodeType":"text"}],"nodeType":"hyperlink"},{"data":{},"marks":[],"value":" is open to approved designers (architects and engineers), manufacturers and integrators (builders). ","nodeType":"text"}],"nodeType":"paragraph"},{"data":{},"content":[{"data":{},"marks":[],"value":"The first step is to ","nodeType":"text"},{"data":{"uri":"/register"},"content":[{"data":{},"marks":[],"value":"register to join","nodeType":"text"}],"nodeType":"hyperlink"},{"data":{},"marks":[],"value":". ","nodeType":"text"}],"nodeType":"paragraph"},{"data":{},"content":[{"data":{},"marks":[],"value":"Next is undergoing the Partner Accreditation Process followed by subscription to the Blink DMI","nodeType":"text"},{"data":{},"marks":[],"value":"®","nodeType":"text"},{"data":{},"marks":[],"value":" platform.","nodeType":"text"}],"nodeType":"paragraph"},{"data":{},"content":[{"data":{},"marks":[],"value":"\\n\\n\\n","nodeType":"text"}],"nodeType":"paragraph"}],"nodeType":"document"}',
                },
              },
            ],
          },
        ],
        id: "9d33a5ec-6ac1-550b-b8b0-05844d3fdb94",
      },
    ],
  },
};

function Faqs() {
  return <Page data={data} />;
}

export default Faqs;
